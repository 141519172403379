<template>
  <navigation index="marketList" ref="navigation">
    <div
      class="topnavigation"
      style="justify-content: space-between; gap: 10px"
    >
      <div style="margin-left: 5px">
        <el-button
          style="width: 100px"
          type="success"
          @click="OpenPaymentWindow"
          > {{ tools.GetLanguageValue('web.支付')  }}</el-button
        >
        <el-button style="width: 100px" type="danger" @click="AccomplishOrder"
          > {{ tools.GetLanguageValue('web.完成订单')  }}</el-button
        >
      </div>
      <div style="padding-right: 5px">
        <el-button style="width: 100px" @click="SelectProductWindow = true"
          > {{ tools.GetLanguageValue('web.选择商品')  }}</el-button
        >
      </div>
    </div>

    <div style="width: 100%">
      <div>
        <el-table
          :data="MakertOpt.OrderItems"
          style="width: 100%"
          border
          :height="MakertItemHeight"
        >
          <el-table-column type="index" label="No." />
          <el-table-column prop="Name" :label="tools.GetLanguageValue('web.商品名称') " width="180">
            <template #default="scope">
              <p
                v-if="scope.row.ShowAllName"
                @click="scope.row.ShowAllName = false"
              >
                {{ scope.row.Name }}
              </p>
              <p v-else class="overflow" @click="scope.row.ShowAllName = true">
                {{ scope.row.Name }}
              </p>
            </template>
          </el-table-column>

          <el-table-column prop="Attributes" label="SKU" width="150">
            <template #default="scope">
              <el-tag
                v-for="(item, index) in scope.row.SkuAttribute"
                :key="index"
                style="margin: 3px"
              >
                {{ item.AttributeName }}
              </el-tag>
            </template>
          </el-table-column>

          <el-table-column prop="Attributes" label="IMEI" width="220">
            <template #default="scope">
              <el-input
                v-model="scope.row.StorageTakeNote.IMEI"
                :placeholder="tools.GetLanguageValue('web.请选择出库商品') "
                style="width: 170px"
              >
                <template #append>
                  <el-button @click="GetStoragRecordByProductId(scope.row)">
                    <el-icon><View /></el-icon>
                  </el-button>
                </template>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column prop="PriceFormate" :label="tools.GetLanguageValue('web.单价') " width="130">
            <template #default="scope">
              <el-button
                size="small"
                v-if="scope.row.PrecioDeVentas.length == 0"
              >
                {{ scope.row.PriceFormate }}
              </el-button>
              <el-popover
                v-if="scope.row.PrecioDeVentas.length > 0"
                placement="right"
                trigger="click"
                :width="300"
              >
                <template #reference>
                  <div>
                    <el-button size="small" type="danger">{{
                      scope.row.PriceFormate
                    }}</el-button>
                  </div>
                </template>
                <template #default>
                  <div style="width: 100%">
                    <p style="border-bottom: 1px solid #eee"> {{ tools.GetLanguageValue('web.可选单价')  }}</p>
                    <div
                      style="
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        overflow: auto;
                      "
                    >
                      <el-button
                        type="success"
                        size="small"
                        v-for="item in scope.row.PrecioDeVentas"
                        style="margin: 5px"
                        :key="item.Id"
                        @click="ChangePrice(scope.row.Id, item.Id)"
                      >
                        {{ item.Price }}
                      </el-button>
                    </div>
                    <el-button style="width: 100%; margin-top: 15px">
                      {{ tools.GetLanguageValue('web.原价')  }}  
                    </el-button>
                  </div>
                </template>
              </el-popover>
            </template>
          </el-table-column>

          <el-table-column prop="Count" :label="tools.GetLanguageValue('web.数量') " width="70" />
          <el-table-column prop="TotalFormate" :label="tools.GetLanguageValue('web.合计')  " width="130">
            <template #default="scope">
              <span style="color: red; font-size: 12pt">
                {{ scope.row.TotalFormate }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="AmountFormate" :label="tools.GetLanguageValue('web.金额') " width="100" />
          <el-table-column prop="TaxRate" :label="tools.GetLanguageValue('web.税率') " width="80" />
          <el-table-column prop="IncludeTax" :label="tools.GetLanguageValue('web.是否含税') " width="100">
            <template #default="scope">
              <span v-if="scope.row.IncludeTax">YES</span>
              <span v-else>NO</span>
            </template>
          </el-table-column>
          <el-table-column prop="TaxAmountFormate" :label="tools.GetLanguageValue('web.税合计') " width="80" />

          <el-table-column fixed="right" :label="tools.GetLanguageValue('web.操作') " width="100">
            <template #default="scope">
              <el-button
                type="danger"
                @click="DeleteOrderItem(scope.row)"
                size="small"
                > {{ tools.GetLanguageValue('web.删除')  }} </el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="makert_detail_content" style="max-width: 1920px; height: 180px">
      <el-divider content-position="left">  {{ tools.GetLanguageValue('web.订单信息')  }}</el-divider>

      <el-form ref="form" label-width="80px">
        <el-row>
          <el-col :span="8">
            <el-form-item :label="tools.GetLanguageValue('web.订单号') " label-width="150">
              <el-input v-model="MakertOpt.OrderNumber" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="tools.GetLanguageValue('web.支付状态') " label-width="150">
              <el-input
                v-model="MakertOpt.PaySatausFormate"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="tools.GetLanguageValue('web.金额') " label-width="150">
              <el-input v-model="MakertOpt.AmountFormate" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="tools.GetLanguageValue('web.税额') " label-width="150">
              <el-input
                v-model="MakertOpt.TaxAmountFormate"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="tools.GetLanguageValue('web.合计') " label-width="150">
              <el-input v-model="MakertOpt.TotalFormate" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="tools.GetLanguageValue('web.已支付金额') " label-width="150">
              <el-input v-model="MakertOpt.PaidTotal" disabled></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item :label="tools.GetLanguageValue('web.创建人') " label-width="150">
              <el-input v-model="MakertOpt.CreateUserName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="tools.GetLanguageValue('web.订单类型') " label-width="150">
              <el-select placeholder="Select" v-model="MakertOpt.OrderType">
                <el-option
                  v-for="item in OrderTypes"
                  :key="item.Key"
                  :label="item.Value"
                  :value="item.Key"
                >
                  {{ item.Value }}
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="tools.GetLanguageValue('web.客户') " label-width="150">
              <el-input
                disabled="true"
                v-model="MakertOpt.ClientName"
                @click="ClientWindow = true"
              >
                <template #append>
                  <el-button @click="ClientWindow = true">
                    <el-icon class="el-icon--right"><Search /></el-icon>
                  </el-button>
                </template>
              </el-input>
            
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-form-item label-width="150"> </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 添加商品 -->
    <el-drawer
      v-model="SelectProductWindow"
      direction="rtl"
      size="900px"
      :with-header="false"
      :show-close="false"
    >
      <template #default>
        <div
          style="width: 700px; display: flex; align-items: center; gap: 15px"
        >
          <el-input v-model="GoodsPadding.ScreenValue">
            <template #append>
              <el-button @click="GetGoods()">
                <el-icon> <Search /> </el-icon>
              </el-button>
            </template>
          </el-input>
          <!-- <el-button @click="GetGoods()">查询</el-button> -->
        </div>
        <br />
        <div :style="{ height: clientHeight - 220 + 'px' }">
          <div style="width: calc(100% - 15px)">
            <el-table
              border
              :data="GoodsDatas"
              :height="clientHeight - 220 + 'px'"
              style="width: calc(100% - 1px)"
            >
              <el-table-column type="index" width="50"> </el-table-column>

              <el-table-column :label="tools.GetLanguageValue('web.商品名称') " width="180" prop="Name">
                <template #default="scope">
                  <p
                    v-if="scope.row.ShowAllName"
                    @click="scope.row.ShowAllName = false"
                  >
                    {{ scope.row.Name }}
                  </p>
                  <p
                    v-else
                    class="overflow"
                    @click="scope.row.ShowAllName = true"
                  >
                    {{ scope.row.Name }}
                  </p>
                </template>
              </el-table-column>

              <el-table-column :label="tools.GetLanguageValue('web.图片') " width="100">
                <template #default="scope">
                  <div style="display: flex; align-items: center">
                    <el-image
                      width="60"
                      height="60"
                      :src="scope.row.ImagesFormat"
                      class="avatar"
                      @click="
                        () => {
                          this.ImageListDetailWindow = true;
                          this.ImageListDetailUrl = scope.row.ImagesFormat;
                        }
                      "
                    />
                  </div>
                </template>
              </el-table-column>

              <el-table-column
              :label="tools.GetLanguageValue('web.商品类型') "
                width="100"
                prop="ProductTypeFormat"
              />

              <el-table-column :label="tools.GetLanguageValue('web.属性') " width="160">
                <template #default="scope">
                  <el-tag
                    v-for="(item, index) in scope.row.SkuAttribute"
                    :key="index"
                    style="margin: 3px"
                  >
                    {{ item.AttributeName }}
                  </el-tag>
                </template>
              </el-table-column>

              <el-table-column :label="tools.GetLanguageValue('web.价格') " width="80" prop="Price" />

              <el-table-column :label="tools.GetLanguageValue('web.是否含税') " width="100">
                <template #default="scope">
                  <el-switch v-model="scope.row.IncludeTax" disabled />
                </template>
              </el-table-column>
              <el-table-column :label="tools.GetLanguageValue('web.成本价') " width="80" prop="Cost" />
              <el-table-column :label="tools.GetLanguageValue('web.编码') " width="80" prop="Code" />

              <el-table-column :label="tools.GetLanguageValue('web.税率') " width="80" prop="TaxRate" />
              <el-table-column :label="tools.GetLanguageValue('web.助记码') " prop="MnemonicCode" width="120" />
              <el-table-column :label="tools.GetLanguageValue('web.备注') " prop="Description" width="120" />

              <el-table-column :label="tools.GetLanguageValue('web.操作') " fixed="right" width="80">
                <template #default="scope">
                  <el-button
                    type="success"
                    size="small"
                    @click="AddProduct(scope.row)"
                    > {{ tools.GetLanguageValue('web.选择')  }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>

            <div
              style="
                margin: 5px;
                height: 50px;
                line-height: 40px;
                display: flex;
                align-items: center;
              "
            >
              <el-pagination
                background
                layout="prev, pager, next"
                @current-change="
                  (s) => {
                    GoodsPadding.Page = s;
                    GetGoods();
                  }
                "
                :page-size="GoodsPadding.Size"
                :page-sizes="[30, 50, 100]"
                :size-change="
                  (s) => {
                    GoodsPadding.Size = s;
                    GetGoods();
                  }
                "
                :total="GoodsPadding.Count"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <div style="flex: auto">
          <el-button @click="SelectProductWindow = false"> {{ tools.GetLanguageValue('web.关闭')  }}</el-button>
          <!-- <el-button @click="AddMakertDetail">确定</el-button> -->
        </div>
      </template>
    </el-drawer>
  </navigation>

  <!-- 打开支付 -->
  <el-dialog v-model="PaymentWindow" :title="tools.GetLanguageValue('web.付款') " :show-close="false">
    <form style="margin: 5px">
      <el-form-item :label="tools.GetLanguageValue('web.订单号') " label-width="150">
        <el-input v-model="MakertPaymatInfo.OrderNumber" disabled />
      </el-form-item>
      <el-form-item :label="tools.GetLanguageValue('web.订单合计') " label-width="150">
        <el-input v-model="MakertPaymatInfo.TotalFormate" disabled />
      </el-form-item>
      <el-form-item :label="tools.GetLanguageValue('web.已支付合计') " label-width="150">
        <el-input v-model="MakertPaymatInfo.PaidTotal" disabled />
      </el-form-item>

      <el-divider content-position="left"> {{ tools.GetLanguageValue('web.支付') }}  </el-divider>

      <el-form-item :label="tools.GetLanguageValue('web.待支付') " label-width="150">
        <el-input-number
          v-model="MakertPaymatInfo.Amount"
          :min="0"
          :max="MakertPaymatInfo.Unpaid"
        />
      </el-form-item>
      <el-form-item :label="tools.GetLanguageValue('web.支付方式') " label-width="150">
        <el-radio-group v-model="MakertPaymatInfo.Payway" size="large">
          <el-radio-button label="0">  {{ tools.GetLanguageValue('web.现金')  }} </el-radio-button>
          <el-radio-button label="100" >  {{ tools.GetLanguageValue('web.信用卡')  }} </el-radio-button>
          <el-radio-button label="200" >  {{ tools.GetLanguageValue('web.支票')  }} </el-radio-button>
          <el-radio-button label="300">  {{ tools.GetLanguageValue('web.转账')  }} </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="tools.GetLanguageValue('web.备注') " label-width="150">
        <el-input v-model="MakertPaymatInfo.Remark" />
      </el-form-item>
    </form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="PaymentWindow = false" type="danger"> {{ tools.GetLanguageValue('web.关闭')  }}</el-button>
        <el-button @click="OrderPayment"> {{ tools.GetLanguageValue('web.确定')  }}</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 选择客户 -->
  <el-dialog v-model="ClientWindow" :title="tools.GetLanguageValue('web.选择客户') " :show-close="false">
    <div style="margin: 10px">
      <div style="display: flex; gap: 10px; height: 40px">
        <el-input
          v-model="ClientPadding.Where.AnyWord"
          @input="GetClient"
          style="margin: 5px 0px"
          :placeholder="tools.GetLanguageValue('web.请输入筛选名称或手机号')  "
        />
        <el-button style="margin: 5px 0px" @click="GetClient" type="primary">
          {{ tools.GetLanguageValue('web.查询')  }}   
        </el-button>
      </div>
      <el-table :data="ClientList" style="width: 100%" border :height="300">
        <el-table-column type="index" label="No." />
        <el-table-column prop="Name" :label="tools.GetLanguageValue('web.客户姓名') " width="180" />

        <el-table-column prop="PhoneNumber" :label="tools.GetLanguageValue('web.手机号') " width="160" />
        <el-table-column prop="Email" :label="tools.GetLanguageValue('web.手机号') " width="160" />
        <el-table-column prop="DocNumber" :label="tools.GetLanguageValue('web.证件号') " width="260" />

        <el-table-column fixed="right" :label="tools.GetLanguageValue('web.操作') " width="100">
          <template #default="scope">
            <el-button
              type="danger"
              @click="SelectClient(scope.row)"
              size="small"
              >{{ tools.GetLanguageValue('web.选择')  }} </el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="ClientWindow = false" type="danger"> {{ tools.GetLanguageValue('web.关闭')  }}</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- XUANZE KUCUN -->
  <el-dialog
    v-model="ProductStorageTakeNoteWindow"
    :title="tools.GetLanguageValue('web.出库选择') "
    :show-close="false"
  >
    <div style="margin: 10px">
      <el-table
        :data="ProductStorageTakeNotes"
        style="width: 100%"
        border
        :height="300"
      >
        <el-table-column type="index" label="No." />
        <el-table-column prop="IMEI" label="IMEI" width="180" />
        <el-table-column prop="SN" label="SN" width="160" />
        <el-table-column prop="Remarks" :label="tools.GetLanguageValue('web.备注') " width="260" />

        <el-table-column fixed="right" :label="tools.GetLanguageValue('web.操作') " width="100">
          <template #default="scope">
            <el-button
              type="danger"
              @click="LockinStorag(scope.row)"
              size="small"
              > {{ tools.GetLanguageValue('web.选择')  }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="ProductStorageTakeNoteWindow = false" type="danger"
          > {{ tools.GetLanguageValue('web.关闭')  }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
  <script >
import axios from "../../../commons/AxiosMethod.js";
import tools from "../../../commons/tools.js";
import navigation from "../../../components/Modules/Navigation.vue";
import PictureCover from "../../../components/PictureCover.vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { Search, View } from "@element-plus/icons-vue";
export default {
  // eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types aboutus
  data() {
    return {
      clientWidth: document.body.clientWidth,
      clientHeight: document.documentElement.clientHeight,
      ProductOpt: null,
      tools:tools,
      ScreenText: "",
      MakertOpt: {
        OrderType: 0,
      },
      MakertDatas: [],
      MakertDetailWindow: false,
      MakertPadding: {
        Page: 1,
        Size: 30,
        Count: 0,
      },
      OrderItemOpt: {}, //当前操作订单行
      SelectProductWindow: false,
      ProductDatas: [],
      CategoryDatas: [],

      ProductOpts: [], //选择的商品
      OrderTypes: [],
      PaymentWindow: false, //支付窗口

      PayMode: "", //支付方式

      MakertPaymatInfo: {},

      ClientList: [], //客户
      ClientWindow: false,
      ClientPadding: {
        Where: {
          AnyWord: "",
        },
      },
      ContentHeight: 900,
      MakertItemHeight: document.documentElement.clientHeight - 300,
      GoodsDatas: [],
      GoodsPadding: {
        Page: 1,
        Size: 10,
        Count: 0,
        Where: {},
      },
      OrderId: "",
      ProductStorageTakeNotes: [], //SUK的库存
      ProductStorageTakeNoteWindow: false,
    };
  },
  name: "App",
  mounted() {
    this.OrderId = this.$route.query.id;

    this.GetGoods();
    this.GetOrderTypes();
    this.GetClient();

    this.GetMarketDetail(this.OrderId);
    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.clientHeight = document.documentElement.clientHeight;
        this.ContentHeight = document.documentElement.clientHeight - 120;
        (this.MakertItemHeight = document.documentElement.clientHeight - 300),
          this.$refs.navigation.onresize();
      })();
    };
    this.ContentHeight = this.clientHeight - 120;
  },

  components: {
    navigation,
    PictureCover,
    Search,
    View,
  },
  methods: {
    LockinStorag(item) {
      const param = {};
      axios.apiMethod(
        "/stores/WorkbenchMarket/LockinStorag",
        "get",
        { orderItemId: this.OrderItemOpt.Id, storageTakeNoteId: item.Id },
        (result) => {
          this.GetMarketDetail(this.MakertOpt.Id);
          this.ProductStorageTakeNoteWindow = false;
        }
      );
    },

    //获取可用库存
    GetStoragRecordByProductId(item) {
      this.OrderItemOpt = item;

      const param = {};
      axios.apiMethod(
        "/stores/WorkbenchMarket/GetStoragRecordByProductId",
        "get",
        { productId: item.ProductId },
        (result) => {
          this.ProductStorageTakeNotes = result.Data;
          this.ProductStorageTakeNoteWindow = true;
        }
      );
    },
    //获取订单类型
    GetOrderTypes() {
      const param = {};
      axios.apiMethod("/sys/SystemData/GetOrderTypes", "get", {}, (result) => {
        this.OrderTypes = result.Data;
      });
    },
    //改价
    ChangePrice(OrderItemId, PrecioDeVentaId) {
      const param = {};
      axios.apiMethod(
        "/stores/WorkbenchMarket/ChangePrice",
        "post",
        {
          PrecioDeVentaId: PrecioDeVentaId,
          OrderItemId: OrderItemId,
        },
        (result) => {
          if (result.Data.IsSuccess) {
            this.GetMarketDetail(this.MakertOpt.Id);
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error",
            });
          }
        }
      );
    },

    // 获取商品
    GetGoods() {
      const param = {};
      axios.apiMethod(
        "/stores/Data/GetProductSKU",
        "post",
        this.GoodsPadding,
        (result) => {
          this.GoodsDatas = result.Data.Datas;
          this.GoodsPadding.Page = result.Data.Page;
          this.GoodsPadding.Size = result.Data.Size;
          this.GoodsPadding.Count = result.Data.Count;
          this.GoodsPadding.ScreenValue = "";

          if (result.Code == 200) {
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error",
            });
          }
        }
      );
    },
    //选择客户
    SelectClient(item) {
      axios.apiMethod(
        "/stores/WorkbenchMarket/OrderSetClient",
        "post",
        {
          Id: this.MakertOpt.Id,
          Clientid: item.Id,
        },
        (result) => {
          if (result.Data.IsSuccess) {
            this.ClientWindow = false;
            this.GetMarketDetail(this.MakertOpt.Id);
          }
        }
      );
    },

    //获取客户
    GetClient() {
      axios.apiMethod(
        "/stores/Client/GetList",
        "post",
        this.ClientPadding,
        (result) => {
          this.ClientList = result.Data.Datas;
        }
      );
    },
    //订单支付
    OrderPayment() {
      axios.apiMethod(
        "/stores/WorkbenchMarket/OrderPayment",
        "post",
        this.MakertPaymatInfo,
        (result) => {
          if (result.Data.IsSuccess) {
            this.PaymentWindow = false;
            this.GetMarketDetail(this.MakertPaymatInfo.OrderId);
          } else {
            ElMessageBox({
              title: "提示",
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },
    //获取客户
    GetClient() {
      axios.apiMethod(
        "/stores/Client/GetList",
        "post",
        this.ClientPadding,
        (result) => {
          this.ClientList = result.Data.Datas;
        }
      );
    },
    //打开支付页面
    OpenPaymentWindow() {
      this.MakertPaymatInfo = {
        OrderId: this.MakertOpt.Id,
        OrderNumber: this.MakertOpt.OrderNumber,
        TotalFormate: this.MakertOpt.TotalFormate,
        PaidTotal: this.MakertOpt.PaidTotal,
        Amount: this.MakertOpt.Unpaid,
        Unpaid: this.MakertOpt.Unpaid,
        Payway: 0,
        Remark: "",
      };
      this.PaymentWindow = true;
    },
    //完成订单
    AccomplishOrder() {
      ElMessageBox.confirm(
        "确定要完成<span style='color:red;font-size:14pt'>[" +
          this.MakertOpt.OrderNumber +
          "]</span>吗" +
          "?",
        "提示",
        {
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          axios.apiMethod(
            "/stores/WorkbenchMarket/AccomplishOrder",
            "post",
            this.MakertOpt,
            (result) => {
              if (result.Data.IsSuccess) {
                this.GetMarketDetail(item.OrderId);
              } else {
                ElMessageBox({
                  title: "提示",
                  message: result.Data.Msg,
                  type: "error",
                });
              }
            }
          );
        })
        .catch(() => {});
    },
    //删除订单行
    DeleteOrderItem(item) {
      ElMessageBox.confirm(
        "确定要删除<span style='color:red;font-size:14pt'>[" +
          item.Name +
          "]</span>吗" +
          "?",
        "提示",
        {
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          axios.apiMethod(
            "/stores/WorkbenchMarket/DeleteOrderItem",
            "post",
            item,
            (result) => {
              if (result.Data.IsSuccess) {
                this.GetMarketDetail(item.OrderId);
              } else {
                ElMessageBox({
                  title: "提示",
                  message: "修改失败",
                  type: "error",
                });
              }
            }
          );
        })
        .catch(() => {});
    },

    //打开选择商品页面
    OpenSelectProductWindow() {
      this.SelectProductWindow = true;
    },
    AddProduct(item) {
      this.ProductOpts = [];
      this.ProductOpts.push(item.Id);
      this.AddMakertDetail();
    },

    // //搜索商品
    // GetProduct(size) {
    //   const param = {};
    //   axios.apiMethod(
    //     "/stores/Data/GetCategoryProduct",
    //     "post",
    //     {
    //       ScreenValue: this.ScreenText,
    //       Size: size,
    //     },
    //     (result) => {
    //       this.CategoryDatas = result.Data;
    //     }
    //   );
    // },

    //打开销售单详细页面
    OpenMakertDetailWindow(item) {
      this.MakertOpt = item;
      this.MakertDetailWindow = true;
      this.GetMarketDetail(item.Id);
    },

    //获取销售单详细
    GetMarketDetail(orderId) {
      const param = {};
      axios.apiMethod(
        "/stores/WorkbenchMarket/GetMarketDetail",
        "get",
        {
          orderId: orderId,
        },

        (result) => {
          if (result.Data.IsSuccess) {
            this.MakertOpt = result.Data.Model;
          }
        }
      );
    },
    //创建销售单
    AddMakertDetail() {
      var param = {
        Id: this.OrderId,
        ProductIds: this.ProductOpts,
      };

      axios.apiMethod(
        "/stores/WorkbenchMarket/InsertOrder",
        "post",
        param,
        (result) => {
          if (result.Data.IsSuccess) {
            //  创建成功

            this.GetMarketDetail(result.Data.Model);
            this.GetMakertList();
          } else {
            ElMessageBox({
              title: "提示",
              message: result.Data.Msg,
              type: "error",
            });
            // this.SelectProductWindow = false;
            // this.MakertDetailWindow = true;
          }
        }
      );
    },
    //获取销售单列表
    GetMakertList() {
      const param = {};
      axios.apiMethod(
        "/stores/WorkbenchMarket/GetOrderList",
        "post",
        this.MakertPadding,
        (result) => {
          this.MakertDatas = result.Data.Datas;
          this.MakertPadding.Page = result.Data.Page;
          this.MakertPadding.Size = result.Data.Size;
          this.MakertPadding.Count = result.Data.Count;
        }
      );
    },
  },
};
</script>
    
  <style scoped lang="less">
.el-button--text {
  margin-right: 15px;
}

.el-select {
  width: 300px;
}

.el-input {
  width: 300px;
}

.dialog-footer button:first-child {
  margin-right: 10px;
}

.dialog-footer {
  height: 50px;
  position: absolute;
  bottom: 1px;
  width: calc(100% - 30px);
  display: flex;
  justify-items: center;
  justify-content: flex-end;
}

.overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
    